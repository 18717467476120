import api from "./api";

class UtilsService {
  getSignUpsById(signups_id) {
    return api.get(`/openroads-signups/${signups_id}`)
  }
  // openrooads vehicles
  getOpenClientsAdds() {
    return api.get("/openroads-vehicles");
  }
  GetOpenroadsInactive() {
    return api.get(`/openroads-inactive-transponders`);
  }
  /* innovative */
  AddComment(data) {
    return api.post(`/openroads-comments`, data);
  }
  getStates() {
    return api.get("/openroads-states");
  }
  getComments(client_id, dept_id) {
    return api.get(`/openroads-comments?client_id=${client_id}&dept_id=${dept_id}`);
  }
  getAgencies() {
    return api.get("/openroads-transponder-agencies");
  }
  getOrders() {
    return api.get("/openroads-orders");
  }
  updateOrder(id, data) {
    return api.post(`/openroads-orders/${id}?_method=put`, data);
  }
  addOrder(data) {
    return api.post("/openroads-orders", data);
  }
  getClients(filter) {
    return api.get("/openroads-clients", { params: filter });
  }
  getCarriers(filter) {
    return api.get("/openroads-clients", { params: filter });
  }
  getClientsLogs() {
    return api.get("/openroads-client-logs");
  }
  getClientsInsights() {
    return api.get("/openroads-clients-insights");
  }
  getClientsAdds() {
    return api.get("/openroads-vehicles?draft=1&client_id");
  }
  getClientDefaultUser(clientId) {
    return api.get(`/openroads-default-user/${clientId}`);
  }
  getClientsUploads() {
    return api.get("/openroads-client-uploads");
  }
  getuserdetails(userId) {
    return api.get(`/openroads-contact-user/${userId}`);
  }
  deleteClientsUploads(vehicle_id) {
    return api.delete(`/openroads-client-uploads/${vehicle_id}`);
  }
  getDepartments(client) {
    return api.get(`/openroads-departments?client_id=${client}`);
  }
  getSubDepartments({ dept }) {
    return api.get("/openroads-sub-departments", null, { dept });
  }
  getSubsidiaries({ client }) {
    return api.get("/openroads-subsidiaries", null, { client });
  }
  getAuthUser() {
    return api.get(`/current_employee`);
  }
  async getOverviewDept(client) {
    return await api.get(`/openroads-default-department/${client}`);
  }
  getclientdetails(clientId) {
    return api.get(`/openroads-clients/${clientId}`);
  }
  getDept(deptId) {
    return api.get(`/openroads-departments/${deptId}`);
  }

  //transactions
  AddExcelTransaction(data) {
    return api.post("/openroads-excel-dumps", data);
  }
  getExcelDetails() {
    return api.get(`/openroads-excel-dumps`);
  }
  getAccounts() {
    return api.get(`/openroads-accounts-balances`);
  }

  //logs
  GetLogs(user_id, draw, length, start, order, pagedir) {
    return api.get(
      `/openroads-logs?user_id=${user_id}&draw=${draw}&length=${length}&start=${start}&order=${order}&pagedir=${pagedir}`
    );
  }
  uniqdashboard(cl_level, client_id) {
    return api.get(`/openroads-dashboard?cl_id=${cl_level}&client_id=${client_id}`);
  }
  getFileValidationProgress(user_id) {
    return api.get(`/openroads-validation-status/${user_id}`);
  }
  Totals() {
    return api.get(`/openroads-dashboard-per-client`);
  }

  //logs
  GetLogs(user_id, draw, length, start, order, pagedir) {
    return api.get(
      `/logs?user_id=${user_id}&draw=${draw}&length=${length}&start=${start}&order=${order}&pagedir=${pagedir}`
    );
  }

  MarkAsInactive(vehicleID, data) {
    return api.patch(`/mark-deactivated/${vehicleID}`, data);
  }
  GetAllInactive() {
    return api.get(`openroads-inactive-transponders`);
  }
  GetInactive() {
    return api.get(`/openroads-inactive-transponders`);
  }
  GetDeactive() {
    return api.get(`/openroads-deactivate-transponder`);
  }
  GetFailedReplenish() {
    return api.get(`/openroads-failed-replenish`);
  }
  getClientAccounts(dept_id) {
    return api.get(`/openroads-accounts-balances?dept_id=${dept_id}`);
  }
  getClientLogs(client_id) {
    return api.get(`/openroads-client-logs?client_id=${client_id}`);
  }
  getRegions() {
    return api.get(`/openroads-travel-regions`);
  }
  sendOtp(data) {
  return api.post('/send_email_otp', data)
} 
  resetPassword(data) {
  return api.post('/updatepassword', data)
} 
}

export default new UtilsService();
