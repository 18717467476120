import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
//Chart js css
require("@/assets/js/plugins/chartjs.min.js");

/* import jQuery/$ for plugins */
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;
// require("@/assets/css/bootstrap.min.css");
require("@/assets/vendor/fontawesome-free/css/all.min.css");
// require("@/assets/css/atlantis.min.css");
require("@/assets/css/bootstrap-datepicker.css");
require("@/assets/js/plugin/webfont/webfont.min.js");
// require("@/assets/css/fonts.min.css");

/* JS dependencies */
// <!-- jQuery core -->
require("@/assets/js/core/jquery.3.2.1.min.js");
require("@/assets/js/core/popper.min.js");
require("@/assets/js/core/bootstrap.min.js");

// <!-- jQuery UI -->
require("@/assets/js/plugin/jquery-ui-1.12.1.custom/jquery-ui.min.js");
require("@/assets/js/plugin/jquery-ui-touch-punch/jquery.ui.touch-punch.min.js");

//Datatable Modules
require("@/assets/datatables/css/dataTables.responsive.css");
require("@/assets/datatables/css/buttons.dataTables.min.css");

require("@/assets/js/plugin/datatables/datatables.min.js");
require("@/assets/datatables/js/dataTables.responsive.js");
//datatable buttons
require("@/assets/datatables/js/dataTables.buttons.min.js");
require("@/assets/datatables/js/jszip.min.js");
require("@/assets/datatables/js/pdfmake.min.js");
require("@/assets/datatables/js/vfs_fonts.js");
require("@/assets/datatables/js/buttons.html5.min.js");
require("@/assets/datatables/js/buttons.print.min.js");
require("@/assets/datatables/js/buttons.colVis.min.js");
//chart js
require("@/assets/js/plugin/chart.js/chart.min.js");
//datepicker
require("@/assets/js/bootstrap-datepicker.js");

require('@/assets/js/plugin/sweetalert/sweetalert.min.js')
require("@/assets/js/atlantis.min.js");
{/* <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script> */}

// import { FontAwesomeIcon } from "./plugins/font-awesome";

import setupInterceptors from "./services/setupInterceptors";

setupInterceptors(store);

createApp(App)
  .use(router)
  .use(store)
  .use(VueSweetalert2)
  // .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
